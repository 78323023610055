/* Circle styling */
.circle {
  display: block;
  width: 150px;
  height: 150px;
  margin: 1em auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 99em;
  border: 5px solid #eee;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}

/* Flag select image positioning */
.flag-select img {
  position: inherit !important;
}

/* Round logo styling */
.round-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: green;
}

.round-logo button {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  margin: 0;
}

.round-logo button:focus {
  outline: none;
}

/* Header button styling */
.headerButtonStyle {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: none; /* default to none */
}

@media screen and (min-width: 1024px) {
  .headerButtonStyle {
    display: inline;
  }
}

/* Header title styling */
.header-title {
  color: rgb(254, 1, 0);
  font-weight: bold;
  font-family: 'Times New Roman';
  font-size: 40px; /* Default font size for laptop screens */
}

@media (max-width: 768px) {
  .header-title {
    font-size: 18px; /* Smaller font size for mobile screens */
  }
}

/* Header subtitle styling */
.header-subtitle {
  color: rgb(254, 1, 0);
  font-family: 'Times New Roman';
  font-size: 20px; /* Default font size for laptop screens */
}

@media (max-width: 768px) {
  .header-subtitle {
    font-size: 9px; /* Smaller font size for mobile screens */
  }
}

/* Popup overlay styling */
.popup-overlay {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content styling */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .popup-content {
    background-image: url('./../../assets/loginBackground.jpg');
  }

  .login-container {
    align-self: flex-end;
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .popup-content {
    background-image: none;
  }

  .login-container {
    align-self: center;
  }
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: 2px solid black;
  font-size: 32px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.no-outline {
  outline: none;
  cursor: default;
}