.filters {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 0; 
  }
  
  .title {
    font-size: 1.5em;
  }
  