.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.auth-title {
  margin-bottom: 20px;
  font-weight: bold;
}

.auth-subtitle {
  margin-bottom: 20px;
  text-align: center;
  color: #555; /* Adjust color as needed */
  font-size: 16px; /* Adjust font size as needed */
}

.auth-form {
  width: 100%;
}

.auth-button {
  margin-top: 20px;
}

.auth-link {
  margin-top: 10px;
}

.auth-google-button-register {
  margin-top: 20px;
}

.auth-help {
  margin-top: 20px;
  cursor: pointer;
  color: #1976d2;
  text-align: center;
}