.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between buttons */
}

.styled-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.styled-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.styled-button:active {
  background-color: #004494;
  transform: scale(1);
}

h3 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.participants-list-container {
  margin-left: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.participants-list h4 {
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.participants-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.participants-list li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.participants-list li:hover {
  background-color: #f0f0f0;
}

.participants-list li input[type="checkbox"] {
  transform: scale(1.2);
  cursor: pointer;
}

.participants-list li span {
  font-weight: bold;
  color: #333;
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}