.participants-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.participants-list {
  list-style-type: none;
  padding: 0;
}

.participant-item {
  margin: 5px 0;
}
