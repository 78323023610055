.animating {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  .animating:hover {
    opacity: 1;
    transform: translateY(-5px);
  }


  nav {
    /* Center the buttons horizontally */
    display: flex;
    justify-content: center;
    /* Add some space between buttons */
    margin: 10px 0;
  }
  
  nav button {
    /* Set some common styles for both buttons */
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    /* Remove default button styling */
    background: none;
    color: #333;
    /* Add some margin between buttons */
    margin: 0 5px;
  }
  
  nav button:disabled {
    /* Style disabled buttons */
    cursor: default; /* Remove pointer cursor */
    opacity: 0.5; /* Reduce opacity */
  }
  
  nav button:hover:not(:disabled) {
    /* Style on hover for non-disabled buttons */
    background-color: #f5f5f5;
  }