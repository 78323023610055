.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  min-height: 600px;
}

.shelf-container-header {
  width: 100%;
  margin-bottom: 10px;
}

.shelf-container-header .products-found {
  float: left;
  margin: 0;
  margin-top: 8px;
}

.shelf-container-header .sort {
  float: right;
}

.shelf-container-header .sort select {
  background-color: #fff;
  outline: none;
  border: 1px solid #ececec;
  border-radius: 2px;
  margin-left: 10px;
  width: auto;
  height: 35px;
  cursor: pointer;
}

.shelf-container-header .sort select:hover {
  border: 1px solid #5b5a5e;
}

.shelf-item {
  width: 25%;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid transparent;
  cursor: pointer;
}

.shelf-item:hover {
  border: 1px solid #eee;
}

.shelf-item:hover .shelf-item__buy-btn {
  background-color: #eabf00;
}

.shelf-item .shelf-stopper {
  position: absolute;
  color: #ececec;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 0.6em;
  background-color: #1b1a20;
  cursor: default;
}

.shelf-item__thumb img {
  width: 100%;
}

.shelf-item__thumb2 img {
  height: 300px;
  width: 100%;
}

.shelf-item__title {
  position: relative;
  padding: 0 200px;
  margin: 10px;
}

.shelf-item__price .val b {
  font-size: 1.5em;
  margin-left: 5px;
}

.shelf-item__price .installment {
  color: #9c9b9b;
}

.shelf-item__buy-btn {
  background-color: #1b1a20;
  color: #fff;
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}