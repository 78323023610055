.video-button {
    background-color: red;
    color: white;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    cursor: 'pointer';
    font-size: 1.5em;
  }
  
  @media (max-width: 600px) {
    .video-button {
      margin: 10px;
      font-size: 0.8em;
      border-radius: px;
      
    }
  }
  
  .video-button-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 15px;
    width: 100%;
    padding: 0 20px;
  }

  .image-button {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0 20px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: 'pointer';
    font-size: 1.5em;
  }

  @media (max-width: 600px) {
    .image-button {
      padding: 0 00px;
      font-size: 1.1em;
    }
  }

  .camp-button {
    padding: 0 20px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: 'pointer';
    font-size: 1.5em;
  }

  @media (max-width: 600px) {
    .camp-button {
      padding: 0 00px;
      font-size: 1.1em;
    }
  }

  @keyframes moveLabel {
    0% {
      left: 0;
    }
    100% {
      left: 100vw;
    }
  }
  
  .moveLabel {
    animation: moveLabel 10s linear infinite;
  }
  
  .moveLabel:hover {
    animation-play-state: paused;
  }
  
  .scrollable-cards-container {
    width: 80%;
    margin: auto;
  }

  
  .scroll-container {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
    scroll-padding: 0 20px; /* Adjust as needed */
  }

  
  .langdingPageCard {
    width: 40vw;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 10px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    text-align: center;
    background-color: lightblue;
  }
  
  .langdingPageCard.focused {
    transform: scale(1.6);
    z-index: 1;
    background-color: lightcoral;

  }

  @media (max-width: 600px) {
    .langdingPageCard.focused {
      transform: scale(1.1);
      z-index: 1;
      width: 60vw;
    }
  }
  

  .firstCardMoveRight {
    width: 40vw;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 10px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    text-align: start;
    background-color: lightblue;
  }
  
  .firstCardMoveRight.focused {
    transform: scale(1.6) translateX(100px);
    z-index: 1;
    background-color: lightcoral;

  }

  @media (max-width: 600px) {
    .firstCardMoveRight.focused {
      transform: scale(1.1) translateX(50px);
      z-index: 1;
      width: 60vw;
      }
  }

  .lastCardMoveRight {
    width: 40vw;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 10px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    text-align: end;
    background-color: lightblue;

  }

  .lastCardMoveRight.focused {
    transform: scale(1.6) translateX(-100px);
    z-index: 1;
    background-color: lightcoral;
  } 

  @media (max-width: 600px) {
    .lastCardMoveRight.focused {
      transform: scale(1.2) translateX(-100px);
      z-index: 1;
      width: 60vw;
    }
  }

.shake {
  animation: shake 0.5s linear infinite;
}

@keyframes shake {
  0% { transform: translateX(0); }
  100% { transform: translateX(-10px); }
}