.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #333;
    font-size: 36px;
    font-family: 'Times New Roman', cursive;
    margin-bottom: 20px;
  }
  .platform-title {
    color: #333;
    font-size: 50px;
    font-family: 'Times New Roman', cursive;
    margin-bottom: 20px;

  }
  
  .service-description {
    color: #555;
    font-size: 18px;
    font-family: 'Times New Roman', sans-serif;
    line-height: 1.5;
  }
  
  /* Different styles for specific words */
  .keyword {
    color: #e44d26; /* Different color for keywords */
    font-weight: bold; /* Make keywords bold */
    text-decoration: underline; /* Underline keywords */
  }

  .video-container {
    position: relative;
    width: 640px; 
    height: 360px; 
  }

  .caption {
    text-align: center;
    margin-top: 10px;
  }