.float-cart {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  z-index: 50;
  transition: right 0.2s;
}

.float-cart--open {
  right: 0;
}

.float-cart__close-btn {
  width: 50px;
  height: 50px;
  color: #ececec;
  background-color: red;
  text-align: center;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 0px;
  cursor: pointer;
}

.float-cart__close-btn:hover {
  background-color: lightcoral;
}

.float-cart .bag {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-image: url('../../static/bag-icon-2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.float-cart .bag--float-cart-closed {
  background-color: white;
  background-size: 50%;
  left: -60px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.float-cart .bag__quantity {
  display: inline-block;
  width: 18px;
  height: 18px;
  color: white;
  font-weight: bold;
  font-size: 0.7em;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  background-color: #eabf00;
  position: absolute;
  bottom: -5px;
  right: 0px;
}

.float-cart__header {
  color: black;
  box-sizing: border-box;
  text-align: center;
  padding: 45px 0;
}

.float-cart__header .header-title {
  font-weight: bold;
  font-size: 1.2em;
  vertical-align: middle;
}

.float-cart__shelf-container {
  position: relative;
  min-height: 280px;
  padding-bottom: 200px;
}

.float-cart__shelf-container .shelf-empty {
  color: black;
  text-align: center;
  line-height: 40px;
}

.float-cart__shelf-container .shelf-message {
  color: black;
  position: relative;
  left: 5%;
}

.float-cart__shelf-container .shelf-item {
  position: relative;
  box-sizing: border-box;
  padding: 5%;
  transition: background-color 0.2s, opacity 0.2s;
}

.float-cart__shelf-container .shelf-item::before {
  content: '';
  width: 90%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 5%;
}

.float-cart__shelf-container .shelf-item--mouseover {
  background: white;
}

.float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .title,
.float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .desc {
  text-decoration: line-through;
  opacity: 0.6;
}

.float-cart__shelf-container .shelf-item--mouseover .shelf-item__price {
  text-decoration: line-through;
  opacity: 0.6;
}

.float-cart__shelf-container .shelf-item__del {
  width: 16px;
  height: 16px;
  top: 5px;
  right: 5%;
  border-radius: 50%;
  position: absolute;
  background-size: auto 100%;
  background-image: url('../../static/sprite_delete-icon.png');
  background-repeat: no-repeat;
  z-index: 2;
  cursor: pointer;
  margin: '5px';
}

.float-cart__shelf-container .shelf-item__del:hover {
  background-position-x: -17px;
}

.float-cart__shelf-container .shelf-item__thumb,
.float-cart__shelf-container .shelf-item__details,
.float-cart__shelf-container .shelf-item__price {
  display: inline-block;
  vertical-align: middle;
}

.float-cart__shelf-container .shelf-item__thumb {
  vertical-align: middle;
  width: 15%;
  margin-right: 3%;
}

.float-cart__shelf-container .shelf-item__thumb img {
  width: 100%;
  height: auto;
}

.float-cart__shelf-container .shelf-item__details {
  width: 57%;
}

.float-cart__shelf-container .shelf-item__details .title {
  color: black;
  margin: 0;
}

.float-cart__shelf-container .shelf-item__details .desc {
  color: #5b5a5e;
  margin: 0;
}

.float-cart__shelf-container .shelf-item__price {
  color: #eabf00;
  text-align: right;
  width: 25%;
}

.float-cart__shelf-container .shelf-item .change-product-button {
  color: black;
  border: 0;
  background-color: white;
  width: 25px;
  height: 25px;
}

.float-cart__shelf-container .shelf-item .change-product-button:disabled {
  opacity: .2;
}

.float-cart__footer {
  box-sizing: border-box;
  padding: 5%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  z-index: 2;
  background-color: white;
}

.float-cart__footer::before {
  content: '';
  width: 100%;
  height: 20px;
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
}

.float-cart__footer .sub,
.float-cart__footer .sub-price {
  color: #5b5a5e;
  vertical-align: middle;
  display: inline-block;
}

.float-cart__footer .sub {
  width: 20%;
}

.float-cart__footer .sub-price {
  width: 80%;
  text-align: right;
}

.float-cart__footer .sub-price__val {
  color: #eabf00;
  font-size: 22px;
}

.float-cart__footer .buy-btn {
  color: #ececec;
  text-transform: uppercase;
  background-color: #0c0b10;
  text-align: center;
  padding: 15px 0;
  margin-top: 40px;
  cursor: pointer;
}

.float-cart__footer .buy-btn:hover {
  background-color: white;
}

@media screen and (min-width: 640px) {
  .float-cart__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: white;
    padding: 10px;
  }

  .float-cart__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #0c0b10;
  }
}

.float-cart__content {
  height: 100%;
  overflow-y: scroll;
}

.checkout-product-details {
  display: inline-block;
  vertical-align: middle;
  height: 20%;
  width: 15%;
  margin-right: 3%;
}

.hovered {
  background-color: blue; /* Define the background color for the hover effect */
}