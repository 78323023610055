.field-container {
  margin-bottom: 20px;
}

.form-container {
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  transition: transform 0.3s ease;
  margin-top: 20px; /* Added margin-top to ensure the container is not truncated */
}

.form-container:hover {
  transform: scale(1.02);
}

.header {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.subheader {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
  color: #555;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textFieldCreateCourse {
  width: 100%;
}

.textFieldCreateCourse input,
.textFieldCreateCourse textarea {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.textFieldCreateCourse input:focus,
.textFieldCreateCourse textarea:focus {
  border-color: #007bff;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.checkboxGroup {
  margin-bottom: 20px; /* Increased margin-bottom for better spacing */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fileInputContainer {
  margin-bottom: 20px; /* Increased margin-bottom for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInputWrapper {
  margin-bottom: 10px;
}

.fileInputLabel {
  flex: 1;
}

.uploadIcon {
  color: green;
  margin-left: 10px;
}

.submitButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}