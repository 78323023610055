/* ProductCarousel.css */
.product-carousel-container {
  max-width: 800px; /* Limit the maximum width for desktop */
  margin: 0 auto; /* Center the carousel */
}

/* Carousel image container */
.carousel-image-container {
  height: 400px; /* Set a fixed height for all images */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* Fallback background color */
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the container without distortion */
}

/* Carousel video container */
.carousel-video-container {
  height: 400px; /* Match the height of the image container */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; /* Black background for video */
}

.carousel-video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the video fits within the container without cropping */
}

/* Fancy "Watch Teacher Video!" button */
.watch-video-container {
  text-align: center;
  margin-top: 20px;
}

.watch-video-button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.watch-video-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Modal styling */
.modal-content {
  border-radius: 10px;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
}

/* Video container in modal */
.video-container {
  width: 100%;
  max-height: 70vh; /* Limit the height to 70% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; /* Black background for video */
}

.modal-video {
  width: 100%;
  height: 100%;
  border: none;
}

/* YouTube iframe specific styling */
iframe.modal-video {
  aspect-ratio: 16 / 9; /* Maintain 16:9 aspect ratio for YouTube videos */
}

/* Local video specific styling */
video.modal-video {
  aspect-ratio: 16 / 9; /* Maintain 16:9 aspect ratio for local videos */
}

/* Play button overlay */
.play-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  cursor: pointer;
}

.play-button {
  width: 80px; /* Size of the play button */
  height: 80px; /* Size of the play button */
  color: white; /* White color for the play icon */
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.play-button:hover {
  opacity: 1;
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}



/* Disabled state for the "Watch Teacher Video!" button */
.watch-video-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}