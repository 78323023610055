.course-title {
  font-size: 2em;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  color: #333;
}

.dropdown-section {
  margin: 20px 0;
  text-align: center;
}

.delete-section {
  text-align: center;
  margin: 20px 0;
}

.headerButtonStyle {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.headerButtonStyle:hover {
  background-color: #c9302c;
}

.confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.confirm-button, .cancel-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button {
  background-color: #d9534f;
  color: #fff;
}

.confirm-button:hover {
  background-color: #c9302c;
}

.cancel-button {
  background-color: #5bc0de;
  color: #fff;
}

.cancel-button:hover {
  background-color: #31b0d5;
}
.chapters-container {
  margin-top: 20px;
}

.chapter-circle {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto; /* Center the circle */
}

.chapter-circle.selected {
  background-color: green;
}

@media (max-width: 576px) {
  .chapter-circle {
    width: 30px;
    height: 30px;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.quiz-content {
  margin-top: 20px;
}

.quiz-card {
  margin: 10px;
  position: relative;
}

.add-question-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.update-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.quiz-card .MuiIconButton-root {
  position: static; /* Remove absolute positioning */
  margin-left: 10px; /* Add some margin for spacing */
}

.fancy-message {
  text-align: center;
  font-size: 1.2em;
  color: #3f51b5;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}