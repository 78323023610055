.participants-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.participant-item {
  margin-bottom: 10px; /* Space between participants */
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between name and checkbox */
  align-items: center; /* Center items vertically */
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.participant-item:hover {
  background-color: #f0f0f0;
}

.participant-item input[type="checkbox"] {
  transform: scale(1.2); /* Make the checkbox larger */
  cursor: pointer; /* Change cursor to pointer */
}

.participant-item span {
  font-weight: bold; /* Make participant name bold */
  color: #333; /* Darker text color */
}