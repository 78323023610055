.subscription-details-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .subscription-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .subscription-card:hover {
    transform: translateY(-10px);
  }
  
  .subscription-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    font-family: 'Times New Roman', serif;
  }
  
  .subscription-subtitle {
    font-size: 18px;
    color: #595959;
    margin-bottom: 10px;
    font-family: 'Times New Roman', serif;
  }
  
  .subscription-text {
    font-size: 16px;
    color: #7f7f7f;
    margin-bottom: 10px;
    line-height: 1.5;
    font-family: 'Times New Roman', serif;
  }
  
  .subscription-text del {
    color: #fe0000;
  }