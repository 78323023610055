.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: white; /* Change to gray if needed */
  border-radius: 50%;
  padding: 10px;
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 8 8"%3E%3Cpath d="M4.5 0L3.5 1 6 3.5 3.5 6 4.5 7 8 3.5 4.5 0z"/%3E%3C/svg%3E');
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 8 8"%3E%3Cpath d="M3.5 0L4.5 1 2 3.5 4.5 6 3.5 7 0 3.5 3.5 0z"/%3E%3C/svg%3E');
}