.drawing-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.color-picker {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pen-style,
.stroke-size,
.text-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  min-width: 100px;
}

.option-button {
  color: #007bff;
  transition: color 0.3s;
}

.option-button:hover {
  color: #0056b3;
}

.option-button:active {
  color: #004494;
}

@media (max-width: 600px) {
  .drawing-options {
    flex-direction: column;
    align-items: stretch;
  }

  .color-picker,
  .pen-style,
  .stroke-size,
  .text-input,
  .option-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .option-button {
    justify-content: center;
  }
}