.quiz-options {
  display: flex;
  flex-direction: column;
  /* Stack options vertically */
}

.quiz-option {
  margin-bottom: 10px;
  /* Add spacing for better readability */
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.dropdown-scrollable .dropdown-menu {
  max-height: 200px;
  /* Adjust the height as needed */
  overflow-y: auto;
}