.animated-link {
  color: blue;
  text-decoration: underline;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
}

.animated-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: blue;
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.animated-link:hover {
  color: darkblue;
}

.animated-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}