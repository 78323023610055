.top-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  /* Style the outer container */
.ButtonGroup {
  display: flex; /* Arrange buttons horizontally */
  gap: 10px; /* Add spacing between buttons */
  /* Adjust as needed (margin, padding, etc.) */
}

/* Style the buttons */
.ButtonGroup button {
  padding: 10px 20px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-weight: bold; /* Add some boldness to the text */
  /* Set initial background color for both buttons */
  background-color: #e0e0e0; /* Adjust as needed */
  color: black; /* Set initial text color */
}

/* Style the active button based on state */
.ButtonGroup button.active {
  /* Change background color for the active button */
  background-color: #007bff; /* Adjust as needed */
  color: white; /* Change text color for the active button */
}
/* Style the form container (optional) */
.form-container {
  /* Adjust margin, padding, or other layout properties as needed */
}

/* Style the fieldset element */
fieldset {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

/* Style the legend element */
legend {
  font-weight: bold;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

/* Style the label element */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Style the input elements with class "InputUserInfo" */
.InputUserInfo {
  width: 100%; /* Adjust width as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Style the error message */
.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 10px;
}

/* Style the success message */
.success {
  background-color: green; /* Background color of the pill */
  color: white; /* Text color of the pill */
  border-radius: 20px; /* Adjust border-radius to make it more like a pill */
  padding: 5px 15px; /* Adjust padding to make it larger */
  display: inline-block; /* Display as an inline block */
  margin: 10px
}

/* Style the submit button */
button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Add disabled state styling (optional) */
  opacity: 1; /* Change to 0.5 or similar for a disabled look */
  cursor: default; /* Change to not-allowed for a disabled look */
}

/* Add additional styles for the input elements with class "InputUserInfo" (optional) */
.InputUserInfo:focus {
  border-color: #007bff; /* Change border color on focus */
  background-color: white;
}

/* Style the "userInfoSection" element */
.userInfoSection {
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
  gap: 10px; /* Add spacing between inputs */
  background-color: transparent;
}

/* Style the "cardInfoSection" element */
.cardInfoSection {
  margin-top: 20px; /* Add space between contact and card information */
  border: 1px solid #ccc; /* Add a solid border */
  padding: 10px; /* Add padding inside the border */
  border-radius: 5px; /* Add rounded corners to the border */
}

.buttonSection {
  margin-top: 20px;
}