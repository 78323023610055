.grade-selector {
    font-family: 'Times New Roman', Times, serif;
    padding: 20px;
  }
  
  .grade-selector h2 {
    font-size: '20px';
    margin-bottom: 10px;
  }
  
  .grade-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  
  .grade-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .grade-button:hover {
    background-color: #f0f0f0;
  }
  
  .grade-button strong {
    font-size: 1.5em;
    margin-left: 5px;
  }
  
  .grade-button.selected {
    background-color: red;
  }
  
  .course-container {
    font-family: 'Times New Roman', Times, serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f8ff;
    border: 2px solid red;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .course-title {
    color: red;
    margin-bottom: 20px;
    font-size: 2em;
    text-decoration: underline;
  }
  
  .course-list {
    list-style-type: none;
    padding: 0;
  }
  
  .course-item {
    margin-bottom: 20px;
  }
  
  .course-item h3 {
    color: #2e8b57;
    margin-bottom: 10px;
    font-size: 1.5em;
    text-decoration: underline;
  }
  
  .course-item p {
    margin: 0;
    color: #555;
    line-height: 1.6;
    font-size: 1.1em;
  }
  
  .ad-image-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .ad-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .ad-image:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .ad-image-container {
      border-radius: 10px;
    }
  
    .ad-image {
      transition: none;
    }
  }
  
  @media (max-width: 480px) {
    .ad-image-container {
      border-radius: 5px;
    }
  }