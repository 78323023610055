/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background-color: black;
}

.bm-menu {
  overflow-y: hidden !important;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  background: black;
  padding: 2.5em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.5em;
  font-size: 1.15em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.bm-item-small {
  display: inline-block;
  padding: 0.5em;
  font-size: 0.75em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.photo {
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.book-thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Adjust the minmax values as needed */
  grid-gap: 20px;
  /* Adjust the gap between thumbnails */
}

.setting-buttons {
  text-align: center;
  font-family: "Old Standard TT";
  cursor: pointer;
  padding: 10px;
  border: 1px solid red;
  border-radius: 10px;
  margin: 10px;
}

.upload-activity-title {
  text-align: center;
  /* Center align the text */
  background-color: #f0f0f0;
  /* Add a light background shade */
  padding: 10px;
  /* Add some padding */
  border-radius: 5px;
  /* Add rounded corners */
  font-family: 'Arial', sans-serif;
  /* Use a better font */
  font-size: 24px;
  /* Increase the font size */
  color: #333;
  /* Set the text color */
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}

.react-calendar-heatmap .color-scale-2 {
  fill: #8cc665;
}

.react-calendar-heatmap .color-scale-3 {
  fill: #44a340;
}

.react-calendar-heatmap .color-scale-4 {
  fill: #1e6823;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.search-bar {
  width: 300px;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
}

.course-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.course-image {
  object-fit: cover;
  height: 140px;
}
