.prodigy-math {
  font-family: 'Times New Roman', serif;
  background-color: #f0f4f8;
  margin: 0 auto;
  width: 80%;
  max-width: 1000px;
  padding: 40px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero {
  text-align: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  padding: 40px;
  border-radius: 10px;
  color: white;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.hero h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.hero button {
  background-color: #fe0000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero button:hover {
  background-color: #cc0000;
}

.stats {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .stats {
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
  }
}

.stats p {
  font-size: 16px;
  color: #333;
}

.savings {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.savings h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan {
  text-align: center;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 10px;
  margin: 10px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.plan:hover {
  transform: translateY(-10px);
}

.plan h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.plan p {
  font-size: 16px;
  color: #7f7f7f;
  margin-bottom: 10px;
  line-height: 1.5;
}

.plan p:nth-child(2) {
  font-weight: bold;
  color: #1976d2;
}

.plan button {
  background-color: #fe0000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.plan button:hover {
  background-color: #cc0000;
}

.choose-plan-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.choose-plan-button button {
  background-color: green;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.choose-plan-button .billing-options {
  margin-left: 10px;
  display: flex;
}

.choose-plan-button .billing-options span {
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 3px;
  margin-right: 5px;
}

.billing-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing-options input[type="radio"] {
  display: none;
}

.billing-options label {
  position: relative;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.billing-options label:hover {
  background: #f0f0f0;
}

.billing-options label span {
  display: block;
}

.billing-options label.selected {
  background: orange;
}

.yearly-option .more-savings {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  background: #ff0;
  color: #000;
  border-radius: 50%;
  width: 90px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5em;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}