.custom-checkbox .MuiCheckbox-root {
    color: red;
  }
  
  .custom-checkbox .MuiCheckbox-root.Mui-checked {
    color: red;
  }
  
  .custom-checkbox .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    background-color: white;
    border-radius: 3px;
  }