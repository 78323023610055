.slide-container {
  width: 100%;
  margin: auto;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 600px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.bm-menu {
  background-color: white;
}

.menu-item {
  padding: 5px;
  color: black;
  outline: none;
  font-family: 'Georgia';
}

.menu-item:hover {
  background-color: #cf4610;
}

.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  min-height: 600px;
}

.shelf-container-header {
  width: 100%;
  margin-bottom: 10px;
}

.shelf-container-header .products-found {
  float: left;
  margin: 0;
  margin-top: 8px;
}

.shelf-container-header .sort {
  float: right;
}

.shelf-container-header .sort select {
  background-color: #fff;
  outline: none;
  border: 1px solid #ececec;
  border-radius: 2px;
  margin-left: 10px;
  width: auto;
  height: 35px;
  cursor: pointer;
}

.shelf-item {
  width: 25%;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid transparent;
  cursor: pointer;
}

.shelf-item:hover {
  border: 1px solid #eee;
}

.shelf-item:hover .shelf-item__buy-btn {
  background-color: #eabf00;
}

.shelf-item .shelf-stopper {
  position: absolute;
  color: #ececec;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 0.6em;
  background-color: #1b1a20;
  cursor: default;
}

.shelf-item .shelf-item__thumb img {
  width: 100%;
}

.shelf-item .shelf-item__title {
  position: relative;
  padding: 0 20px;
  height: 45px;
}

.shelf-item .shelf-item__title::before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #eabf00;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -10px;
}

.shelf-item .shelf-item__price {
  height: 60px;
}

.shelf-item .shelf-item__price .val b {
  font-size: 1.5em;
  margin-left: 5px;
}

.shelf-item .shelf-item__price .installment {
  color: #9c9b9b;
}

.shelf-item .shelf-item__buy-btn {
  background-color: #1b1a20;
  color: #fff;
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.change-product-button {
  color: #b7b7b7;
  border: 0;
  background-color: #000;
  width: 25px;
  height: 25px;
}

.change-product-button:disabled {
  opacity: .2;
}

.cursorPointer {
  cursor: pointer;
}

.contact-page {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info-card {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.social-media {
  margin-top: 2rem;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0.5rem;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}