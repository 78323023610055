/* Imported font */
@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Resetting default body styles */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Times New Roman', Times, serif;
}

/* Additional body styles */
body {
  color: #1b1a20;
}

/* Main content wrapper */
main {
  display: flex;
  padding: 20px 2%;
  max-width: 1200px;
  margin: 50px auto 0 auto;
}

/* Media query for smaller screens */
@media only screen and (max-width: 1024px) {
  body .filters {
    width: 20%;
  }

  body .shelf-container {
    width: 80%;
  }

  body .shelf-container .shelf-item {
    width: 33.33%;
  }
}

/* Media query for even smaller screens */
@media only screen and (max-width: 640px) {
  body .filters {
    width: 25%;
  }

  body .shelf-container {
    width: 75%;
  }

  body .shelf-container .shelf-item {
    width: 50%;
    padding: 10px;
  }

  body .shelf-container .shelf-item__title {
    margin-top: 5px;
    padding: 0;
  }

  body .float-cart {
    width: 100%;
    right: -100%;
  }

  body .float-cart--open {
    right: 0;
  }

  body .float-cart__close-btn {
    left: 0px;
    z-index: 2;
  }

  body .float-cart__header {
    padding: 25px 0;
  }
}

/* Media query for even smaller screens */
@media only screen and (max-width: 460px) {
  body main {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    margin-top: 42px;
  }

  body .filters {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  body .filters .title {
    margin-bottom: 15px;
  }

  body .shelf-container-header .products-found {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  body .shelf-container-header .sort {
    width: 100%;
    text-align: center;
  }

  body .shelf-container {
    width: 100%;
  }

  body .shelf-container .shelf-item {
    width: 50%;
  }

  body .shelf-container .shelf-item__buy-btn {
    display: none;
  }
}

#page-wrap {
}