/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background-color: black;
}


.bm-menu {
  overflow-y: hidden !important;
}


/* Wrapper for item list */
.bm-item-list {
  color: white;
  background: black;
  padding: 2.5em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.5em;
  font-size: 1.15em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.bm-item-small {
  display: inline-block;
  padding: 0.5em;
  font-size: 0.75em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.container {
  margin-top: 8%;
  margin-bottom: 8%;
}

.inner {
  overflow: hidden;
  cursor: pointer;
}

.inner img {

  transition: all 1.5s ease;
  /* transform: scale(1.5); */
}

.inner:hover img {
  transform: scale(1.1);
}

