

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background-color: white;
}

/* General sidebar styles */
/* .bm-menu {
background: blue;
padding: 2.5em 1.5em 0;
font-size: 1.15em;
} */

/* Morph shape necessary with bubble or elastic */
/* .bm-morph-shape {
fill: blue;
} */

.bm-menu {
  overflow-y: hidden !important;
  background-color: white;
}


/* Wrapper for item list */
.bm-item-list {
  color: black;
  background-color: white;
  padding: 2.5em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.5em;
  font-size: 1.15em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.bm-sub-item {
  padding: 0.5em;
  font-size: 0.75em;
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  position: relative;
}

.bm-sub-item::before {
  content: '•'; /* Add this line */
  position: absolute; /* Add this line */
  left: -10px; /* Add this line */
  top: 50%; /* Add this line */
  transform: translateY(-50%); /* Add this line */
}

.bm-item-small {
  display: inline-block;
  padding: 0.5em;
  font-size: 0.75em;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

/* Styling of overlay */
/* .bm-overlay {
background: rgba(0, 0, 0, 0.3);
background-color: #a90000;
} */

.menuButtonStyle {
  background-color: orangered;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: inline; /* default to none */
}

.menuProfileButtonStyle {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: inline; /* default to none */
}