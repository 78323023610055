.score-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.score-card h2 {
  color: #333;
  margin-bottom: 20px;
}

.top-three {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.top-participant {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.top-participant img {
  width: 50px;
  height: 50px;
}

.top-participant h3 {
  margin: 10px 0;
  color: #333;
}

.top-participant p {
  color: #666;
}

.participants-list {
  list-style: none;
  padding: 0;
}

.participant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.participant svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.participant span {
  flex: 1;
  text-align: left;
  color: #333;
}

.participant span:last-child {
  text-align: right;
  color: #666;
}
