.clock {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border: 5px solid black;
  border-radius: 50%;
  position: relative;
  margin-bottom: 20px;
}

.clock-face {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(-3px); /* Fixes the clock hand position */
}

.hand {
  width: 50%;
  height: 6px;
  background: black;
  position: absolute;
  top: 50%;
  transform-origin: 100%;
  transition: all 0.05s;
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}
